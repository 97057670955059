export const BRAND_PAGE_ID = 3833
export const PROJECT_PAGE_ID = 4148
export const GUIDES_PAGE_ID = 4150
export const INSPIRATION_PAGE_ID = 4169
export const BLOG_PAGE_ID = 3820
export const BARGAIN_CORNER_ID = 3100
export const ACTIVATE_ACCOUNT_PAGE_ID = 5103
export const WORK_FOR_US_PAGE_ID = 5124

export const STOCK_ID = {
    IN_STOCK: ['1', '2'],
    OUT_OF_STOCK: ['9', '3'],
    PREBOOK: ['14'],
}

export const PREBOOK_SV = "Beräknas åter i lager"; // Swedish
export const PREBOOK_DE = "Beregnet igen på lager"; // Danish
export const PREBOOK_NO = "Beregnet igjen på lager"; // Norwegian
export const PREBOOK_EN = "Expected back in stock"; // English


export const VALID_PREBOOK_NAMES = [
    PREBOOK_SV, 
    PREBOOK_DE, 
    PREBOOK_NO,
    PREBOOK_EN,
];

export const PRODUCT_ADD_TO_CART_MOBILE_ID = "add-to-cart-container-mobile-id"